<template>
  <landing-card
    :title="title"
    icon-type="el-icon-fa-bars"
    :description="description"
    :viewButton="false"
    :checkRTL="checkRTL"
    v-if="!isLoading"
  >
    <div class="survey-content">
      <h2>
        <app-i18n-wl code="entities.survey.header" :lang="lang"/>
      </h2>
      <div class="survey-box">
        <div class="box-title">
          <app-i18n-wl code="entities.survey.qualityTitle" :lang="lang" />
        </div>
        <div :class="'box-options' + checkPadMar">
          <el-radio-group v-model="formData.quality">
            <el-radio
              v-for="(q, index) in qualities"
              :key="index"
              :label="index"
            >
              <app-i18n-wl :code="'entities.survey.qualities.' + q" :lang="lang"/>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="survey-box">
        <div class="box-title">
          <app-i18n-wl code="entities.survey.difficultyTitle" :lang="lang"/>
        </div>
        <div :class="'box-options' + checkPadMar">
          <el-radio-group v-model="formData.difficulty">
            <el-radio
              v-for="(d, index) in difficulties"
              :key="index"
              :label="index"
            >
              <app-i18n-wl :code="'entities.survey.difficulties.' + d" :lang="lang"/>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="survey-message">
        <el-input
          type="textarea"
          :placeholder="placeholder"
          v-model="formData.message"
          :autosize="{ minRows: 5 }"
          autofocus
        >
        </el-input>
      </div>
      <div class="survey-submit">
        <el-button
          :disabled="saveLoading"
          @click="doSubmit"
          icon="el-icon-fa-floppy-o"
          type="primary"
          :class="checkPadMar"
        >
          <app-i18n-wl code="common.send" :lang="lang" />
        </el-button>
      </div>
    </div>
  </landing-card>
</template>

<script>
import { i18nWL } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'online-exam-survey',
  props: ['examId'],
  async created() {
    try {
      await this.fetchCurrentExamForSurvey(this.examId);
    } catch (error) {
      //todo goster kullaniciaya?
      // console.log(
      //   'Exam and Question data retrieve exception: ',
      //   error.message,
      // );
    }
    this.isExamFetched = true;
  },
  data() {
    return {
      formData: {
        quality: -1,
        difficulty: -1,
        message: ''
      },
      qualities: ['poor', 'average', 'good', 'excellent', 'outstanding'],
      difficulties: ['soEasy', 'easy', 'average', 'difficult', 'soDifficult'],
      isExamFetched: false,
      lang: 'en'
    };
  },
  mounted() {
    this.lang = this.currentExam ? this.currentExam.examLanguage.code : 'en';
  },
  computed: {
    ...mapGetters({
      saveLoading: 'supportRequests/form/saveLoading',
      checkRTL: 'onlineExam/main/checkRTL',
      isRTL: 'onlineExam/main/isRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      isLoading: 'onlineExam/main/isLoading',
      currentExam: 'onlineExam/main/currentExam',
      currentUser: 'auth/currentUser'
    }),
    description() {
      return i18nWL('entities.survey.description', this.lang);
    },
    placeholder() {
      return i18nWL('entities.survey.placeholder', this.lang);
    },
    title() {
      return i18nWL('entities.survey.title', this.lang);
    },
    checkPadMar() {
      return this.isRTL ? ' rightPadMar' : '';
    }
  },
  methods: {
    ...mapActions({
      doSurvey: 'supportRequests/form/doSurvey',
      fetchCurrentExamForSurvey: 'onlineExam/main/fetchCurrentExamForSurvey'
    }),
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }
      //
      // const { id, ...values } = formSchema.cast(this.model);

      this.formData.exam = this.examId;
      this.formData.username = this.currentUser.fullName;
      this.formData.useremail = this.currentUser.email;
      this.formData.examname = this.currentExam.name;
      const values = {
        type: 'Comment',
        message: JSON.stringify(this.formData)
      };
      return this.doSurvey(values);
    }
  }
};
</script>

<style scoped>
.survey-box {
  padding: 1rem;
}

.box-title {
  padding: 0.5rem;
  font-weight: 500;
}

.box-options {
  padding: 0.5rem;
}

h5.subtitle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.survey-content h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.survey-submit {
  margin-top: 0.5rem;
}
</style>

<style>
.rightPadMar span.el-radio__label {
  padding-right: 10px;
  padding-left: 0px;
}

.rightPadMar .el-radio {
  margin-left: 30px;
  margin-right: 0px;
}

.rightPadMar.el-button > span {
  margin-left: 0px;
  margin-right: 5px;
}
</style>
